<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-04-03 10:33:41
 * @LastEditTime: 2023-11-17 16:17:24
 * @Descripttion: 【作业考试】作业
-->
<style lang="scss" scoped>
.homework {
  @include innerPage;
  @include pageHead(-10px);
  @include defalutTable(calc(100% - 224px), 38px);
  @include pageFoot;

  .page-head {
    height: 224px;
    flex-wrap: wrap;
    padding-top: 0;

    .head-wrapper.column {
      margin-top: 12px;
    }

    .page-tab {
      justify-content: flex-start;
      margin-left: 24%;

      .tabs {
        border-bottom: none;
        justify-content: flex-start;
      }

      .more-subject {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #6c4ecb;
        margin-right: auto;
        margin-left: 60px;
        margin-top: 6px;
        cursor: pointer;

        &:hover {
          background: #8971d5;
        }

        .iconfont {
          font-size: 18px;
          text-align: center;
          line-height: 24px;
          color: #fff;
        }
      }
    }

    .single-search {
      width: 90.64%;

      .el-button:not(.search) {
        margin-left: 10px;
      }
    }
  }
}

.dialog-container {
  &--distribute {
    box-sizing: border-box;
    padding: 24px 0;

    .date-picker {
      ::v-deep {
        .el-input {
          width: 100%;

          &__inner {
            padding: 0 44px 0 26px;
          }
        }
      }
    }
  }

  &--homework {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .form-wrapper {
      width: calc(100% + 32px);
      max-height: 500px;
      box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 146px 0 56px;
      overflow: hidden;
      overflow-y: auto;

      .el-select {
        margin-bottom: 12px;
      }

      .homework-questions {
        width: calc(100% + 202px);
        margin-left: -56px;
        box-sizing: border-box;
        padding: 40px 136px 0 0;
        margin-top: 30px;
        border-top: 1px solid rgba($color: #9e9fa2, $alpha: 0.18);

        .dialog-question-list {
          margin-top: 0;
          width: 100%;

          .question--data h5 .el-image {
            margin-right: 0;
          }

          .question-item {
            position: relative;
          }
        }

        .operate-group {
          width: 114px;
          position: absolute;
          top: -4px;
          right: -120px;
          @include flexBox;
        }
      }
    }

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }
}

.dialog {
  &-question {
    box-sizing: border-box;
    padding: 40px 8px 40px 32px;

    &.detail {
      .dialog-question--head {
        width: 484px;
      }

      .question--data {
        margin-right: 40px;
      }
    }

    &--head {
      width: 432px;
      margin-left: 18px;
      box-sizing: border-box;
    }

    &--title,
    &--desc {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    &--desc {
      @include flexBox;
      align-items: baseline;

      span {
        flex-shrink: 0;
      }
    }

    &-list {
      margin-top: 28px;

      .operate-group {
        width: 116px;
        flex-shrink: 0;
        margin-left: 10px;
        text-align: left;
      }

      .question,
      .subtopic {
        &-item {
          width: 100%;
          margin-bottom: 28px;
          @include flexBox;
          align-items: baseline;

          &:last-child {
            margin-bottom: 0;
          }

          &.combination {
            .combination--title {
              font-size: 16px;
              color: #404040;
              line-height: 24px;
              margin: 18px 0;
            }
          }
        }

        &--num,
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--num {
          color: #1f1f1f;
          font-size: 16px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }

      .subtopic {
        &--num {
          font-size: 14px;
        }

        &--type {
          margin-right: 6px;
        }

        &--title {
          line-height: 24px;
          @include flexBox;
          align-items: baseline;

          span {
            text-align: justify;
            margin-right: 10px;
          }

          .el-image {
            flex-shrink: 0;
            margin-top: -7px;
          }
        }

        &--options,
        &--knowledge,
        &--answer {
          width: 100%;
        }
      }
    }

    &--practise {
      max-height: 540px;
      margin-left: -20px;
      box-sizing: border-box;
      padding-top: 8px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox;
      align-items: baseline;

      .question {
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }
    }
  }

  &-scroll {
    width: calc(100% + 20px);
    max-height: 540px;
    box-sizing: border-box;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
.distribute-dialog .el-dialog {
  transform: translateY(-80%);
}
</style>
<template>
  <section class="homework">
    <div class="page-head">
      <div class="page-head--inner">
        <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
        <div class="head-wrapper column">
          <breadcrumb />
          <div class="wrap-plate">
            <div class="single-search">
              <div class="search-ipt">
                <el-input
                  v-model.trim="searchForm.query_word"
                  maxlength="100"
                  placeholder="搜索章节(课堂)名称"
                  clearable
                >
                  <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
              </div>
              <el-button
                type="custom_primary search"
                size="medium"
                @click="searchData"
                >搜索</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="page-tab">
        <ul class="tabs bold">
          <li
            class="tab"
            :class="{ current: searchForm.sysub_id == item.sysub_id }"
            v-for="(item, index) in teachSubject.slice(0, 5)"
            :key="item.sysub_id"
            @click="changeTab(index)"
          >
            {{ item.sysub_name }}
          </li>
        </ul>
        <el-dropdown
          class="more-subject"
          placement="bottom-start"
          v-if="teachSubject.length > 5"
        >
          <p class="iconfont">&#xe8aa;</p>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in teachSubject.slice(
                5,
                teachSubject.length
              )"
              :key="item.sysub_id"
              @click.native="changeTab(index + 5)"
            >
              {{ item.sysub_name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="tableData" height="100%">
          <el-table-column
            align="center"
            prop="teuse_name"
            label="授课教师"
            min-width="13.09%"
          />
          <el-table-column
            align="center"
            prop="sysub_name"
            label="科目"
            min-width="7.6%"
          />
          <el-table-column
            align="center"
            prop="sccou_cha_title"
            label="章节(课堂)"
            min-width="10.73%"
          />
          <el-table-column
            align="center"
            prop="start_time"
            label="课程时间"
            min-width="12.89%"
          />
          <el-table-column align="center" label="累计人数" min-width="6.86%">
            <template slot-scope="scope"
              >{{ scope.row.allNum || 0 }}人</template
            >
          </el-table-column>
          <el-table-column align="center" label="批阅" min-width="6.64%">
            <template slot-scope="scope"
              >{{ scope.row.readNum || 0 }}人</template
            >
          </el-table-column>
          <el-table-column align="center" label="操作" width="120px">
            <template slot-scope="scope">
              <el-button
                type="custom_danger"
                size="mini"
                @click="toReadPractice(scope.row.tecla_id)"
                >批阅</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <customPagination
          :current="tablePage.pageIndex"
          :total="tablePage.total"
          @pageChange="flippingPage"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { $practiceList } from "@api/homeworkExam";
import { $getTeachSubject } from "@api/teaching";
import { mapState } from "vuex";
import { formatFile } from "@utils";
import customPagination from "@comp/customPagination";
export default {
  name: "he_homework",
  components: { customPagination },
  computed: {
    ...mapState("common", ["questionType"]),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      grade: null,
      class: null,
      searchForm: {}, // 筛选数据
      teachSubject: [], // 教师所授科目
      tableData: [], // 作业列表数据
      /* 表格分页参数 */
      tablePage: {
        pageIndex: 1,
        total: 0,
      },
    };
  },
  async created() {
    let { id } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    await this.getTeachSubject();
    this.getData();
  },
  /** 路由keepAlive为true时，页面数据更新 */
  async activated() {
    await this.getTeachSubject();
  },
  /** 页面跳转/返回前的回调 */
  beforeRouteLeave(to, from, next) {
    this.$routeAlive(to, from, next, ["HE_HOMEWORKREAD"]);
  },
  methods: {
    /** 获取教材数据 */
    async getData() {
      let params = {
        ...this.searchForm,
        sccla_id: this.class,
        pageindex: this.tablePage.pageIndex,
      };
      let { data: res } = await $practiceList(params);
      this.tablePage.total = res.allcount;
      this.tableData = res.data;
      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.tableData = [];
      this.tablePage.pageIndex = val;
      this.getData();
    },
    /** 获取教师所授科目 */
    async getTeachSubject() {
      let { data } = await $getTeachSubject(this.class);
      this.teachSubject = data;
      if (this.searchForm.sysub_id) {
        data.map((item, index) => {
          if (item.sysub_id == this.searchForm.sysub_id) this.changeTab(index);
        });
      } else {
        this.searchForm = {
          ...this.searchForm,
          sysub_id: data[0].sysub_id,
        };
      }
    },
    /** 切换数据相关科目 */
    changeTab(index) {
      this.searchForm = {
        sccla_id: this.class,
        sysub_id: this.teachSubject[index].sysub_id,
      };
      if (index > 4) {
        let firData = this.teachSubject[index];
        let replaceData = this.teachSubject[4];
        this.teachSubject[4] = { ...firData };
        this.teachSubject[index] = { ...replaceData };
      }
      this.searchData();
    },
    /** 筛选数据 */
    searchData() {
      this.tableData = [];
      this.tablePage.pageIndex = 1;
      this.getData();
    },
    /**
     * 批阅课堂练习
     * @param {number} id 作业id
     */
    toReadPractice(id) {
      this.$router.push({
        name: "HE_PRACTICEWORKREAD",
        params: {
          class: this.$route.params.class,
          id: this.$route.params.id,
          tecla: id,
        },
      });
    },
  },
};
</script>
